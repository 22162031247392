import { computed, defineComponent } from '@vue/composition-api';
import { getBookingStatus } from '@/Booking/utils/bookings';
import { dateString } from '@/util/dates';
import { cleanObjectForGTM } from '@/util/gtm/gtmFunctions';
export default defineComponent({
    props: {
        booking: {
            type: Object,
            default: null
        }
    },
    setup(props, context) {
        const { root } = context;
        //** Analytics **/
        // Check if query includes payment param (true or false)
        const payment = Object.keys(root.$route.query).includes('payment');
        // Check if booking status become 'PAID'
        const isPaymentSuccessful = getBookingStatus(props.booking) === 'PAID';
        if (payment) {
            if (isPaymentSuccessful) {
                let analyticsData = cleanObjectForGTM(props.booking);
                analyticsData.booking_start = dateString(analyticsData.slot_start);
                analyticsData.booking_end = dateString(analyticsData.slot_start);
                if (analyticsData.booking_type === 'DIRECT') {
                    // Send event
                    globalThis.$gtm.trackEvent({
                        event: 'direct_booking_paid',
                        category: 'bookings',
                        action: 'direct_booking_paid',
                        label: 'Direct booking paid',
                        value: props.booking?.booking_id, //@todo this should contain montetary value of booking
                        noninteraction: false
                    });
                }
                else {
                    // Send event
                    globalThis.$gtm.trackEvent({
                        event: 'request_booking_paid',
                        category: 'bookings',
                        action: 'paid',
                        label: 'Booking request paid',
                        value: props.booking?.booking_id, //@todo this should contain montetary value of booking
                        noninteraction: false
                    });
                }
            }
            // Redirect with cleaning query params
            globalThis.$router.replace({ query: {} });
        }
        return {
            status: computed(() => getBookingStatus(props.booking))
        };
    }
});
